form.signup-form {
    width: 500px !important;
  
    margin: auto;
  }
 
  .signUp-page.d-flex {
    margin: auto;
    background: #ffffff;
    max-width: 1200px;
    border-radius: 26px;
    margin-top: 30px !important;
    margin-bottom: 30px !important;
    justify-content: space-between;
  }
  .login-image {
    width: 600px !important;
    
  }
  .signup-header {
    font-size: 36px;
    font-family: "poppins";
    font-weight: 700;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  button.btn.btn-start.signUp-btn{
    margin: 15px auto 30px auto ;
    color: #ffffff;
    width: 250px;
    height: 50px;
    font-family: "poppins";
    font-weight: 700!important;
    font-size: 18px!important;
    line-height: 24px!important;
    background-color: #02416F;
}
.signUp-inputs {
    margin-bottom: 40px;
  }