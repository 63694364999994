body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f4f5f7 !important;
  /* background: #ffffff; */
}

body {
  font-family: "Poppins" !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.login-image-full{
  background-image: url("../src/assets/home-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 40px 0px;
  border-radius: 10px;

}
.signUp-image-full{
  background-image: url("../src/assets/home-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 40px 0px;
  border-radius: 10px;
}
.login-inputs,.signUp-inputss {
  /* margin-bottom: 40px; */
  background: #ffff;
  width: 600px;
  margin: auto;
  border-radius: 18px;
  padding: 20px 46px;
}
.container-login{
 max-width:1200px;
  /* height: 500px; */

  margin: 30px auto;
  ;
}
nav.navbar.navbar-expand-lg.nav-color.second-nav {
  height: 60px;
  background: #DAC509!important;
}

nav.navbar.navbar-expand-lg.nav-color.first-nav{
  height: 80px;
  padding: 0px!important;
  & img {
    width: 110px!important;
    height: 62px!important;
    object-fit: cover;
  }
  & input.form-control.home-search{
    font-family: "poppins";
    /* color: #205A84!important; */
    width: 550px!important;
    height: 55px!important;
    color:#02416F!important ;
    font-size: 18px!important;
    line-height: 27px;
    font-weight: 500;
    margin-left: 140px;
    font-family: Poppins;
    border: solid #02416F 2px!important;
    border-radius: 10px!important;
    height: 55px!important;
    padding-left: 20px!important;
    padding-right: 5px!important;
  }
    & input.form-control.home-search::placeholder{
      color: #02416F!important;
      
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    & input.form-control.home-search:focus{
      outline: none!important;
    }
    .search-con{
      position: relative;
      & button.btn.search-btn{
        position: absolute;
      right: 5px;
      top: 10%;
      border-radius: 7px;
      background: #02416F;
      color: #FFF;
      display: flex;
      padding: 10px 40px;
      align-items: center;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
      }
    }
   
  
}
.container-navigation{
  width: 1200px;
  padding: 0px;
  margin: auto;
}

input.form-control.home-search:focus{
  outline: none!important;
}
.details-card {
  background: transparent !important;
  border: none !important;
}
.separator-line {
  width: 2px;
  height: 50px;
  background: #02416F;
  margin-right: 20px;
  margin-left: 20px;
}
.cart-text,.user-login,.user-name {
  
  color: #02416F;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  margin-right: 15px;
  line-height: normal;
}
.details-title {
  font-size: 18px;
  font-weight: 400;
  line-height: 17px;
  line-height: 30px;
  /* text-align: center; */
  color: #333333;
}
.grid-price-con{
  display: flex;
}

.details-price {
  font-size: 18px;
  font-weight: 600;
  line-height: 17px;
  line-height: 30px;
  /* text-align: center; */
  color: #333333;
  /* margin-left: -5px; */
  padding: 0px 0px 16px 0px;
}
.container.view-details-con{
  max-width: 1200px!important;
  background: #ffffff;
  padding: 24px ;
  border-radius: 10px;
  padding-bottom: 40px;
  overflow-x: hidden;
}

.details-cart-btn {
  width: 200px !important;
  height: 50px !important;
  background-color:#02416F!important ;
  margin-top: 30px;
  margin-bottom: 30px;
  border-radius: 9px !important;
  border: none !important;
  font-size: 18px !important;
  line-height: 17px !important;
  text-align: center !important;
  color: #ffffff!important;
}
/* button.btn.details-cart-btn:disabled {
  cursor: not-allowed;
} */
.increment-container {
  width: 130px;
  height: 30px;
  object-fit: contain;
  border-radius: 7px;
  background-color: #ffffff;
}

.numMonths-label {
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 17px !important;
  /* text-align: center !important; */
  color: #000 !important;
}

.increment-btn {
  background: #E2C227 !important;
  border: none !important;
  color: #fffdff !important;
  font-size: 18px !important;
  font-weight: 700;
  width: 30px;
  height: 35px;
}
span.card-tex-list {
  font-weight: 700;
  font-size: 26px;
  color: #E2C227;
  padding-right: 10px;
  padding-left: 10px;
}

td,
th {
  padding: 20px;
}
nav.d-flex.justify-content-center.pagination{
  height: 70px;
  /* width:94%; */
    object-fit: contain;
    border-radius: 10px;
    background-color: #ffffff;
    margin-bottom: 25px;
    justify-content: flex-end;
    /* margin-left: 20px; */
    align-items: flex-end;
}
a.page-link {
  /* background: red; */
  color: #174363!important;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border-radius: 50%; */
}
.active>.page-link{
  color: #ffffff!important;
  background-color:#174363!important ;
  border-color:#174363!important  ;
}

.mb-44 {
  margin-bottom: 44px !important;
}

.mt-44 {
  margin-top: 44px !important;
}

.mr-4 {
  margin-right: 4px !important;
}
.mr-8 {
  margin-right: 4px !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  font-family:  "poppins";
}
p.mr-8 {
  padding-top: 6px;
}
.password-header {
  font-size: 36px;
  margin: 80px auto 0px auto;
  font-weight: 700;
  margin-bottom: 20px;
  text-align: center;
  font-family: "poppins";
  color: rgb(19 46 66);
}

.subheader {
  text-align: center;
  font-family: "poppins";
  font-size: 18px;
  font-weight: 500;
  color: #174363;
  margin: 30px 0px;
}

.form-control-label {
  font-family: "poppins";
  font-size: 18px;
  color: rgb(19 46 66);
  line-height: 17px;
  margin-bottom: 16px;
}

.form-control-input {
  border: 1px solid rgb(20 21 38) !important;
  width: 500px !important;
  height: 60px !important;
  border-radius: 6px !important;
  /* margin-bottom: 44px; */
}



.submit-btn {
  background-color: rgb(226 194 39) !important;
  color: #fff !important;
  width: 220px;
  height: 55px;
  margin-top: 70px;
  /* margin-top: 20px; */
  align-self: center;
  font-size: 22px !important;
}

.container.forgot-password-container
 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width:800px;
  /* height: 80vh; */
  margin-top: 80px;
  background-color: #fffdff;
  border-radius: 26px;
  padding-bottom: 80px;
}
.reset-password-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width:800px!important;
  /* height: 80vh; */
  margin-top: 60px;
  background-color: #fffdff;
  border-radius: 26px;
  padding-bottom: 60px;
}
button.btn.submit-btnTwo.d-flex.justify-content-center.align-items-center{
  
    color: #ffffff;
    width: 200px;
    height: 50px;
    font-family: "poppins";
    font-weight: 700!important;
    font-size: 18px!important;
    line-height: 17px!important;
    background-color: #02416F;
    border-radius: 8px;
    margin: 20px auto!important;
    
}
.reset-account{
  margin-bottom: 40px!important;
  margin-top: 20px!important;
}

/* .container.forgot-password-container > *,
.container.reset-password-container > * {
  margin-bottom: 20px;
} */

/* .container.forgot-password-container,
.container.reset-password-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
} */

.container.forgot-password-container form,
.container.reset-password-container form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.invalid-feedback {
  font-family: "poppins" !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #F46A6A !important;
  margin-top: 8px !important;
  margin-left: 15px;
  margin-bottom: 16px;
}

.cou-title > a:after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f078" !important;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #000;
}

.accordion {
  margin-bottom: 19px;
}

.accordion-item {
  border: none !important;
  border-bottom: 2px solid #eff4f9 !important;
  border-radius: unset !important;
}

.accordion-body {
  display: grid;
  padding-left: 0 !important;
  padding-top: 0 !important;
}

.accordion-button {
  padding-left: 7px !important;
    padding-right: 10px!important;
}

.accordion-header > button {
  font-family: "poppins";
  color: #333333;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  box-shadow: none !important;
  border: none !important;
  background: none !important;
}

.subcategory-item {
  font-family: "poppins";
  font-size: 16px;
  line-height: 24px;

  font-weight: 400;
  text-align: left;
  color: #151526;
}

.form-check-input {
  width: 20px !important;
  height: 20px !important;
  border:solid 1.5px #174363 !important;
  margin-right: 15px;
}

.form-check-input:checked {
  background-color: #174363 !important;
  border: none !important;
}

.form-check-input:focus {
  box-shadow: none !important;
}

.price-input {
  padding-left: 5px!important;
  width: 50%;
  height: 35px;
  border-radius: 7px;
  border: 1.5px solid #174363;
}

.filters-col {
  /* width: 365px; */
  margin-right: 20px;
  box-shadow: 0px 0px 8px 0px lightgrey;
  border-radius: 10px;
  height: fit-content;
}

.filters-col .card {
  width: 100%;
  /* margin-right: 10px; */
  /* height: 100vh; */
  border: none;
  padding-bottom: 40px;
 
}
.mb-4{
  margin-bottom: 0px!important;
}

.listing-col {
  width: 100%;
}

.listing-col .card {
  /* width: 31%;
  margin-left: 21px; */
  border: none;
  margin-bottom: 20px;
}

.listing-col .grid-view .card {
  /* width: 283px; */
}


/* .list-card img {
  border-radius: 26px;
  padding: 21px 19px 15px 19px;
} */
span.lazy-load-image-background {
  height: 230px;
  border-radius: 10px!important;
  padding: 0px 0px 20px 0px!important;
}
img.card-img-top {
  height: 230px;
  
  border-radius: 10px!important;
  padding: 0px 0px 20px 0px!important;
}
.container.grid-con {
  /* width: 1200px; */
  padding: 0px;
  /* margin-left: 10px; */
}
/* .container{
  width: 1200px!important;
  padding: 0px!important;
} */
.container-signUp{
  max-width: 1200px;
  margin: 30px auto;
}
.list-card img {
  width: 400px;
  padding-bottom: 0px!important;
  margin-right: 30px;
  margin-left: -12px;
  object-fit: cover;
}
.card-img-list{
  height: 180px;
  padding-top:8px;
  padding-left: 4px;
}

.list-card .cart-btn {
  margin-left: auto;
}

.list-card {
  display: flex;
  width:100%;
  border-radius: 6px;
  background: #fff;
  margin-bottom: 20px;
  /* margin-left: 10px; */
}
.map-view{
  width:50vw;
  /* border: 1px solid #000; */
  margin: 2% auto;
  padding: 16px 0px;
}
/* .list-view .card-body {
  width: 65%;
} */

.list-view .location-container {
  margin-top: 20px;
}

.list-view .details-btn {
  margin-top: 0;
}
.price-input::placeholder{

}
.card-body {
  padding: 0 !important;
  margin: auto;
  width: 100%;
}

.location-container,
.street-container {
  display: flex;
  align-items: baseline;
  gap: 8px;
  /* Adjust the spacing between the icon and the text */
  margin-bottom: 8px;
  margin-top: 8px;
}
/* .empty-location{
  display: none;
} */

.location-text {
  width: 100%;
  /* height: 31px; */
  font-size: 16px;
  color: #333333;
  
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.street-text {
  /* width: 113px; */
  /* height: 25px; */
  padding-left: 6px;
  color: #333333;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}


.separator {
  /* width: 90%; */
  height: 2px;
  background-color: #eff4f9;
}

.cart-svg {
  /* margin-top: 25px; */
  /* margin-left: 25px; */
  cursor: pointer;
}

.filter-separator {
  /* width: 353.4px; */
  height: 2px;
  background-color: #eff4f9;
  margin-top: 12px;
}

th,td{
  padding: 20px 40px;
    font-size: 16px;
    color: #000000;
    font-weight: 400;
    line-height: 17px;
}
.location-dropdown {
  height: 35px;
  border: solid 1.5px #02416F ;
  border-radius: 7px;
  background-color: #ffffff;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.3px;
  text-align: left;
  color: #02416F;
  width: 100%;
  /* margin-right: 8px; */
  padding-left: 10px;
}
.card.mb-4.col-md-3 {
  border-radius: 10px;
}
.location-dropdown-two {
  height: 35px;
  width: 100%;
  border: solid 1.5px #02416F;
  border-radius: 7px;
  background-color: #ffffff;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.3px;
  text-align: left;
  color: #02416F;
  padding-left: 10px;
}
input:focus-visible,
select:focus-visible {
  outline: none !important;
}
.list-price-con{
  display: flex;
}
.price-text {
  /* width: 99px;
  height: 25px; */
  padding-left: 8px;
  font-size: 16px;
  font-weight: 400;
  font-weight: "normal";
  font-style: "normal";
  line-height: 24px;
  /* padding-left: 10px!important; */
  letter-spacing: 0;
  text-align: "left";
  color: #333333;
}
input.price-input::placeholder {
color: #02416F!important;

/* text-align: center; */
font-family: Poppins;
font-size: 13px!important;
font-style: normal;
font-weight: 400!important;
line-height: normal!important;
padding-left: 5px;
}
/* nav.navbar{
  position: fixed;
    z-index: 9999;
    width: 100%;
} */
.details-btn {
  /* width: 140px;*/
  height: 40px; 
  border-radius: 9px !important;
  background-color: #02416F !important;
  color: #ffffff !important;
  padding: 10px 20px!important;
  /* margin-top: 25px; */
  display: flex!important;
  align-items: center!important;
  justify-content: center!important;
  font-family: Poppins;
font-size: 14px!important;
font-style: normal;
font-weight: 400!important;
line-height: normal;
}
/* .row>*{
  padding-left: 0px!important;
  padding-right: 0px!important;
} */

.grid-view .cart-btn {
  width: 43.4px;
  height: 40px;
  margin-top: 12px;
}

.view-container {
  /* width: 94%; */
  height: 70px;
  object-fit: contain;
  border-radius: 10px;
  box-shadow: 0px 0px 8px 0px lightgrey;
  background-color: #ffffff;
  margin-bottom: 25px;
  justify-content: flex-end;
  /* margin-left: 10px; */
}

.view-btn {
  /* width: 37px !important;
  height: 38.1px !important; */
  border: solid 1px #e8eef8 !important;
  background-color: #f7f8fa !important;
}

.view-btn-active {
  /* width: 37px !important;
  height: 38.1px !important; */
  background-color: #02416F !important;
}
.non-stop{
  color: #FFF;

font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 21px;
text-transform: uppercase;
}
.btn-group {
  margin-right: 20px;
}



.home-title {
  font-size: 22px;
  font-weight: 700;
  line-height: 14px;
  text-align: center;
  padding-top: 40px;
  margin-bottom: 40px;
}

.home-subtitle {
  font-size: 22px;
  font-family: "poppins";
  line-height: 17px;
  text-align: center;
  font-weight: 400;
  padding-bottom: 40px;
}
.separator-line-two{
  width: 80px;
  height: 2px;
  border-radius: 7px;
  background:#02416F ;
}

.categories-title {
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
  /* text-align: center; */
  color: #02416F;
  line-height: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.filters-body {
  padding-left: 6%;
  padding-right: 6%;
}

.form-label {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
  color: #02416F;
  margin-bottom: 16px!important;
}
.accordion-button:not(.collapsed){
  color: #333333!important; 
}
.update-view-btn {
  /* width: 171px !important;
  height: 45px !important; */
  border-radius: 9px !important;
  background-color: #02416F !important;
  color: #ffffff !important;
  margin-top: 23px;
  width: 100%;
  line-height: 21px!important;
  /* margin-bottom: 20px; */
  font-size: 14px!important;
  padding: 10px 20px!important;
  font-weight: 400!important;
}

.home-search {
  width: 250px !important;
  height: 45px !important;
  border-radius: 23.5px !important;
  border: solid 1.5px #174363 !important;
}

a.nav-link {
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
  color: #ffffff !important;
  &:first-child{
    padding-left: 0px!important;
  }
}

a.nav-link.active {
  font-weight: 700;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  color: #174363 !important;
}
.cartView-container {
  max-width: 1200px;
  min-height: 500px;
  /* margin-right: 140px !important;
  margin-left: 140px !important; */
  background: #fff;
  border-radius: 10px;
  margin-top: 30px !important;
  margin: 30px auto 40px auto;
}
ul {
  padding: 0;
  list-style-type: none;
}
h2.cartheader-container {
  color: #02416F;
  width: 50px;
  margin: 40px;
  /* padding-top: 30px !important;
  margin-bottom: 30px !important;
  margin-left: 45px; */
  font-size: 24px;
  /* width: 500px; */
  text-align: center;
  color: #02416F;
  font-family: "poppins";
  font-weight: 700;
  line-height: 30px;
}
h2.cartheader-summary {
  
  color: #132e42;

  padding-top: 30px !important;
  margin-bottom: 30px !important;
  margin-left: 100px;
  font-size: 24px;
  line-height: 17px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
h2.header-summary{
  text-align: center;
  margin-bottom: 20px;
  color: #02416F;
  font-size: 16px;
  line-height: 26px;
  font-weight: 700;
}
.summary-line{
  height: 2px;
  width: 40px;
  margin: 0px auto 20px auto;
  background:#02416F ;
}
.cart-headers-con {
  display: flex;
  align-items: center;
}
.cartitems-container {
  /* background: red; */
  width: 760px;
  margin-bottom: 40px;
  /* height: 724px; */
  margin-left: 40px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 6px 4px 13px 0px rgba(0, 0, 0, 0.25) !important;
}
ul.cart-items-borders {
  padding: 20px;
  margin: auto;
}
.image-dimensions {
  width: 280px;
  height: 160px;
  padding: 8px;
  /* border-radius: 22px !important; */
}
p.item-price {
  font-size: 18px;
  font-family: 'Poppins';
  line-height: 27px;
  color: #333333;
  margin-bottom: 0px!important;
}

li.cart-item {
 display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1.5px solid #02416F;
  border-radius: 5px;
  padding: 8px 15px ;
  margin-bottom: 20px;

}
.item-image {
  border-radius: 5px;
  background: #fff;
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.25);
  margin-right: 20px !important;
}
.image-details{
  display: flex;
  align-items: flex-start;
}
.cart-items-borders {
  width: 750px;
  padding-top: 22px;
}
.item-details {
  padding-top: 6px;
}
h4.item-name {
  font-size: 16px;
 ;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #333333;

  margin-bottom: 10px !important;
}
p.item-code {
  font-size: 16px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #333333;
  margin-bottom: 10px !important;
}
p.price-egp {
  color: #000000;
  font-size: 11px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 900;
  padding-top: 3px;
  line-height: 19px;
  /* margin-bottom: 10px !important; */
}
.counter-container {
  width: 50px;
  height: 110px;
  border-radius: 8px;
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.25);
}
.add-item {
  height: 35px;
  /* background: red; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.numof-items {
  height: 35px;
  background: #2C84C5;
  /* opacity: 0.4; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.delete-item {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  margin-left: 20%;
  height: 35px;
}
.numof-items {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  color: #000;
  font-size: 18px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
button.btn.cart-back-btn {
  width: 300px;
  height: 50px;
  background: #02416F;
  color: #ffffff;
  margin: 0px auto 30px 200px;
  /* margin-bottom: 29px; */
  font-size: 18px;
  font-family: "Poppins";
  line-height: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.backto-marketplace {
  width: 731px;
}
.all-cart-info {
  display: flex;
  /* margin-left: -70px; */
  padding: 0px!important;
    align-items: baseline;
    justify-content: center;
}
.cart-summary {
  margin-left: 50px;
  margin-right: 40px;
  padding: 20px!important;
  border-radius: 8px;
 box-shadow:6px 4px 13px 0px rgba(0, 0, 0, 0.25) !important;
  width: 370px;
  padding: 15px;
  height: fit-content;
}
span.bold-word {
  font-size: 16px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #333333;
}
span.extra-bold-word {
  font-size: 18px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #333333;
}
span.light-word {
  font-size: 16px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #333333;
}
.items-spacing {
  display: flex;
  justify-content: space-between;
}
.custom-line {
  background: #eff4f9;
  width: 300px;
  height: 2px;
  margin: 15px auto;
}
button.btn.btn-submit {
  background: #02416F;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 230px;
  margin: 30px auto;
  height: 50px;
  color: #fff;
  font-size: 18px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
footer {
  position: relative;
}
button.btn.btn-sendMsg{
  position: absolute;
  top: -20px;
  left: 41.5%;
padding: 10px 40px;
align-items: center;

border-radius: 80px;
background: #DAC509;
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 230px; */
  margin:  auto;
  height: 50px;
  color: #fff;
  font-size: 18px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.cart-items {
  margin: auto;
  /* font-size: 48px; */
  font-size: 48px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.nav-color{
  background-color: #fffdff!important;
}
.remove-headers {
  display: none;
}
p.empty-cart {
  
  padding-top: 180px;
    font-size: 36px;
    text-align: center;
    font-family: 'Poppins';
}
a.cart-link {
  text-decoration: none;
  font-size: 36px;
  text-align: center;
  font-family: 'Poppins';
  margin-bottom: 100px;
}
button.btn.btn-sm.btn-secondary {
    /* width: 23px; */
    display: flex;
    align-items: center;
    background-color: transparent;
    justify-content: center;
    color: #02416F;
    border: none;
    /* margin-top: 27px; */
    height: 40px;
    font-size: 36px;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}
p.card-text {
  /* margin-top: 30px; */ 
  font-weight: 700;
  width: 30px;
  display: flex;
  align-items: center;
  color: #ffffff;
  justify-content: center;
  text-align: center;
  border-radius: 6px;
  padding-top: 5px;
  padding-bottom: 5px;
  height: 40px!important;
  margin-bottom: 0px;
  font-size: 20px;
  background-color: #02416F;
}
.market-cart{
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  display: flex;
}
.cart-counter{
  font-size: 16px;
    line-height: 17px;
    font-weight: 900;
    color: #174363;
}
.market-profile{
  display: flex;
    flex-direction: column;
    align-items: center;
}
.card-body-details {
  width: 100%;
  margin-left: 40px;
}
.card.details-card {
  width: 100%;
  margin-top: 40px;
}
.map-view-container{
    background: #ffffff;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 10px;
    /* margin-left: 10px; */
}
.first-nav{
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
  z-index: 99999;
}
.second-nav{
  position: fixed;
  top: 40px;
  right: 0px;
  left: 0px;
  z-index: 99999;
}
.plz{
  padding-left: 0;
}
.mb-tw{
  margin-bottom: 20vh;
}
.card-request{
  padding:8px;
  display: flex;
}
img.card-img-request {
  height: 100px;
  width: auto;
  border-radius: 10px!important;
}
.ml-4{
  margin-left: 4px;
}
.ml-8{
  margin-left: 8px;
}