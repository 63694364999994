/* .label {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
} */

.create-container {
  margin: 30px auto 30px auto;
  border-radius: 26px;
  background: #fff;
  max-width: 1200px;
  padding: 30px 40px 40px 40px;
}
.create-edit-ad{
  color: #132e42;
    /* padding-top: 20px !important; */
    /* margin-bottom: 20px !important; */
    /* margin-left: 100px; */
    font-size: 24px;
    margin-bottom: 30px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    line-height: 17px;
}
.label .h-1-wrapper {
  /* border: 0px none;
  height: 54px; */
  /* width: 328px; */
}

.text-wrapper {
  color: #132E42;
  font-family: "Poppins";
  font-size: 20px;
  margin-bottom: 0px;
  padding-bottom: 16px;
  line-height: 17px;
  font-weight: 700;
  letter-spacing: 0;
  
}
.text-wrapper-two {
  color: #132E42;
  font-family: "Poppins";
  font-size: 16px;
  margin-bottom: 0px;
  padding-bottom: 16px;
  line-height: 17px;
  font-weight: 700;
  letter-spacing: 0;
  margin-left: 4px;
  
}
.category-dropdown {
  border-radius: 7px;
  border: 1.5px solid #F3F5F6;
  background: #F3F5F6;
  width: 500px;
  height: 55px;
  padding: 0px 10px;
}

.category-title {
  color:  #174363;
  /* small headers */
  font-family: 'Poppins';
    font-size: 18px!important;
    font-style: normal;
    margin-left: 8px;
    font-weight: 400!important;
    margin-bottom: 16px!important;
    line-height: 17px!important;
}

.data-section {
  padding: 8px 0;
}
/* 
label {
  margin-bottom: 24px;
} */

.create-input {
  border-radius: 7px;
  background:  #F3F5F6;
  display: flex;
  width: 250px;
  border-color: transparent;
  height: 55px;
  padding: 0px 18px;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
}

.submit-btn {
  display: flex !important;
  align-items: center !important;
  margin: auto !important;
  justify-content: center !important;
}
p.account-status{
  margin-right: 16px;
  color: #000;
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 20px;
  margin-bottom: 0px!important;
}

.pl{
  padding-left: 12px;
}
.upMargin{
  margin-top: 6px;
  margin-bottom: 6px;
}
.filePondWrapper{
  width : 260px;
  min-width: 200px;
}
.location-modal{
  margin-top: 30vh;
}