.contact-us.container {
    margin: 170px auto 30px auto;
    border-radius: 26px;
    background: #fff;
    max-width: 1200px;
    padding-top: 30px ;
    padding-bottom: 30px;
}
h1.how-itWorks-header {
    padding-top: 10px;
    margin: 0px auto 40px auto;
    width: 600px;
}
.contact-form{
    
    padding: 24px 40px 0px 40px;
    border-radius: 18px;

    box-shadow: 0px 0px 8px 0px lightgrey;
}
.form-group{
    margin-bottom: 16px;
}
.contact-detailss{
    padding-left: 40px;
    padding-top: 20px;
}
h2.call-us-header,h2.msg-us-header {
    color: #333333;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 33px;
    margin-left: 16px;
    margin-bottom: 0px;
}
.call-header,.msg-header{
    margin-bottom: 16px;
}
.all-contact-info{
    p {
        color: #333;
        font-family: Poppins;
        width: 480px;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
    }
    b {
        color: #333;
    }
}

.divider-contact{
    height: 2px;
    background: #eff4f9;
    margin-top: 40px;
    margin-bottom: 40px;
    width: 80%;
}
.sign-contact-msg {
    width: 480px;
    color: #333;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
form.email-con.d-flex {
    width: 480px;
    margin-top: 16px;
}
button.btn.email-btn {
    border-radius: 5px;
    background: #02416F;
    width: 150px;
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
   height: 55px;
   margin-left: 16px;
}
input.form-control.email-sign-up {
    width: 330px;
}
.face-bookk,.insta-gram,.linked-in{
    width: 40px;
    height: 40px;
    background: #02416F;
    border-radius: 100%;
    margin-right: 16px;
}
.social-con{
    margin-top: 32px;
}
textarea#messageArea {
    width: 500px;
    font-family: "poppins";
    border-radius: 5px;
    color: #000!important;
    border: solid #02416F 2px!important;
    font-size: 18px!important;
    line-height: 26px!important;
    font-weight: 400!important;
    padding-top: 20px;
    padding-left: 10px;
}
textarea#messageArea::placeholder{
    color: #02416F!important;

/* text-align: center; */
font-family: Poppins;
font-size: 13px!important;
font-style: normal;
font-weight: 400!important;
line-height: normal!important;

}
label.form-control-label.text-label {
    font-family: "poppins";
    font-size: 16px;
    font-weight: 600;
    /* padding-top: 20px; */
    color: #333333;
    line-height: 24px;
    margin-bottom: 8px;
}