img.login-image-dimensions {
  width: 600px !important;
  height: 100%;
  border-radius: 26px;
}
form.login-form {
  width: 500px !important;

  margin: auto;
}
.login-page.d-flex {
  margin: auto;
  background: #ffffff;
  max-width:1200px;
  border-radius: 26px;
  margin-top: 30px !important;
  margin-bottom: 30px !important;
  justify-content: space-between;
}
/* .login-image {
  width: 500px !important;
  
} */
.login-header,.signUp-header {
  font-size: 24px;
  /* width: 500px; */
  text-align: center;
  color: #02416F;
  font-family: "poppins";
  font-weight: 700;
  line-height: 30px;
  margin: 20px auto 20px auto;
  
}
.email-label{
  font-family: "poppins";
  font-size: 16px;
  font-weight: 600;
  padding-top: 20px;
  color: #333333;
  line-height: 24px;
  margin-bottom: 8px;
}
.login-line{
  height: 2px;
  width: 40px;
  margin: auto;
  background:#02416F;
}
.header-con{
  /* width: 80px; */
  text-align: center;
    margin: auto;
    display: flex;
    align-items: center;
}
.password-label{
  font-family: "poppins";
  font-size: 16px;
  font-weight: 600;
  /* padding-top: 20px; */
  color: #333333;
  line-height: 24px;
  margin-bottom: 8px;
}
input.form-control {
    font-family: "poppins";
    color: #000!important;
    border: solid #02416F 2px;

    font-size: 18px!important;
    line-height: 26px!important;
    height: 55px;
    font-weight: 400!important;
    
}
input.form-control:focus{
  border: solid #02416F  
   2px !important;
}
input.form-control::placeholder {
  color: #02416F;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  
  }
  button.btn.btn-start.login-btn{
    margin: 15px auto 30px auto;
    color: #ffffff;
    width: 200px;
    height: 50px;
    font-family: "poppins";
    font-weight: 700!important;
    font-size: 18px!important;
    line-height: 24px!important;
    background-color: #02416F;
}
/* .login-inputs {
  margin: 90px auto;
} */