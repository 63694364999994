.d-flex.container.view-details {
    border-radius: 26px;
    max-width: 1200px;
    background: #ffffff;
    margin-top: 30px;
    margin-bottom: 30px;
}
.price{
  display: flex;
  align-items: flex-start;
  padding-top: 8px;
  gap: 16px;
  /* margin-bottom: 8px; */
}
.street-containerr ,.details-title{
  display: flex;
  align-items: baseline;
  gap: 16px;
  margin-bottom: 8px;
}
.listing-img {
    width: 100%;
    height: 300px;
    margin-top: 20px;
    margin-left: 15px;
    border-radius: 18px;
  }
  .view-rows-details {
    margin-left: 20px;
  }
  .product-desc {
    font-size: 20px;
    font-weight: 700!important;
    color: #02416F;
    font-weight: 26;
    line-height: 17px;
    text-align: left;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .desc-row {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-top: 20px;
  }
  
  .label-desc {
    font-size: 18px;
    color: #333333;
    font-weight: 700;
    line-height: 17px;
    text-align: left;
    margin: 0;
  }
  
  .value-desc {
    font-size: 18px;
    font-weight: 400;
    color: #333333;
    line-height: 27px;
    text-align: left;
    margin-top: 4px;
  }


  