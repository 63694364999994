/* Search bar container styles */
.search-bar-container {
    position: relative;
    width: 360px; /* Adjust the width as needed */
  }
  
  /* Input field styles */
  .inputBox {
    width: 70%;
    padding: 8px;
    border: 2px solid #adadad;
  }
  .dib{
    display: inline-block;
  }
  
  /* Suggestions dropdown styles */
  .suggestions {
    position: absolute;
    z-index: 1;
    background-color: #fff;
    border: 1px solid #ccc;
    max-height: 150px; /* Set the max height for scrollability */
    overflow-y: auto; /* Enable vertical scrolling when list exceeds max height */
    list-style: none;
    padding: 0;
    margin: 0;
    width: 98%; /* Match the width of the container */
    margin-bottom: 3%;
  }
  
  /* List item styles */
  .suggestions li {
    padding: 8px;
    cursor: pointer;
  }
  
  /* Hover effect for list items */
  .suggestions li:hover {
    background-color: #323272;
  }
.h5-label{
  margin-bottom: 4%;
}