.noi{
    width:200px;
    height: auto;
}
.carousel-image {
    height: 400px;
    width: auto;
    max-width: 100%;
    object-fit: contain;
  }
  .img-Carousel-container{
      margin-top:24px;
      overflow-x: hidden;
  }