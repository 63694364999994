.about-us.container {
    margin: 170px auto 30px auto;
    border-radius: 26px;
    background: #fff;
    max-width: 1200px;
    padding-top: 30px ;
    padding-bottom: 30px;
}
h3.company-details{
    color: #333333;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 33px;
    
    margin-bottom: 24px;
}
p.first-text,p.second-text,.p.third-text{
    color: #333;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
}
.all-about-info.d-flex.col-md-12 {
    padding-right: 40px;
    padding-left: 40px;
    padding-top:40px;
}
.about-pic.col-md-4 {
    margin-right: 100px;
    margin-top: -40px;
}
.mission-vision{
   margin-top: 120px;
   margin-bottom: 120px;
}
.mission-con.col-md-6.d-flex, .vision-con.col-md-6.d-flex{
    /* background: #f2f2f2; */
    box-shadow: 0px 0px 8px 0px lightgrey;
    border-radius: 10px !important;
    padding: 48px 40px;
}
.mission-con.col-md-6.d-flex{
    margin-right: 20px;
}
.vision-con.col-md-6.d-flex{
    margin-left: 20px;
}
h3.mission-header,h3.vision-header {
    color: #333333;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 24px;
    line-height: 33px;
}
p.mission-body,p.vision-body {
    color: #333;
    font-family: Poppins;
    /* width: 480px; */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
}