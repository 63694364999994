.all-reqs-container {
    margin: 30px auto 30px auto;
    border-radius: 10px;
    background: #fff;
    max-width: 1200px;
    padding: 30px 40px 40px 40px;
}
.all-reqs-header{
    color: #02416F;
       padding-top: 20px !important; 
      /* margin-bottom: 20px !important; */
      /* margin-left: 100px; */
      font-size: 22px;
      text-align: center;
      margin-bottom: 40px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      line-height: 30px;
  }
  .request-con{
    margin-left: 32px;
    margin-right: 32px;
    border-radius: 8px;
    background: #fff;
    box-shadow: 6px 4px 13px 0px rgba(0, 0, 0, 0.25) !important;
    margin-bottom: 32px;
    padding: 16px 40px;
  }
  h6.requests{
    color: #333333;
  font-family: "Poppins";
  font-size: 16px;
  padding-top: 20px;
 
  line-height: 24px;
  font-weight: 700;
  letter-spacing: 0;
  margin-bottom: 16px;
    padding-bottom: 0px;
  }
  button.btn.btn-danger{
    background-color: #F46A6A!important;
    height: 40px;
  }
  button.btn.btn-primary{
    background-color: #174363!important;
    height: 40px;
  }
  span.badge.bg-primary{
    height: 40px;
    background-color: #f1b44c!important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  span.badge.bg-success{
  background-color: #34c38f!important;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  }
  span.badge.bg-danger{
    background-color: #F46A6A!important;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    }