.clearBtn{
    display: block;
    color: #cc0000;
  }
/* General container adjustments for small screens */
@media (max-width: 768px) {
  .container {
    max-width: 100%;
    margin-top: 100px; /* Reduce margin on smaller screens */
    padding-left: 15px;
    padding-right: 15px;
  }

  .filters-col {
    width: 100%;
    margin-bottom: 20px; /* Add some spacing between columns on small screens */
  }

  .listing-col {
    width: 100%;
  }

  .view-container {
    flex-direction: column; /* Stack elements vertically on small screens */
    padding: 10px; /* Reduce padding */
  }

  .d-flex {
    flex-direction: column; /* Ensure d-flex elements stack vertically */
  }

  .mb-4 {
    margin-bottom: 10px; /* Reduce bottom margin */
  }
}
.lightGray{
  font-size: small;
  color: #919191;
}
/* Extra small devices (phones, less than 576px) */
@media (max-width: 576px) {
  .container {
    margin-top: 80px; /* Further reduce top margin */
  }

  .view-container {
    padding: 5px;
  }

  .mb-4 {
    margin-bottom: 5px;
  }
}
