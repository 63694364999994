.unique-color{
    background-color: #DAC509;
    height: 5px;
}
.top-bottom-con{
    background-color: #02416F;
}
h4.footer-headers{
color: #E5E5E5!important;
margin-top: 50px!important;
margin-bottom: 16px!important;
font-family: Poppins!important;
font-size: 14px!important;
font-style: normal!important;
font-weight: 400!important;
line-height: normal!important;
}
li.footer-item a ,span.contact-footer, p.news-content {
     
        text-decoration: none;
        color: #E5E5E5;
        font-family: Poppins;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    
}
ul.footer-links{
    padding-left: 0px!important;
}
/* .contact{

    width: 218px;
}
.news {
    width: 308px;
} */
p.news-content ,span.contact-footer{
    width: 218px;
}
.contact-details {
    width: 230px;
}
form.fotter-email-con {
    width: 306px;
}
span.email-footer,.contact-details a {
    color: #EBD401;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration: none;
}
button.btn.footer-email-btn {
    border-radius: 5px;
    background: #DAC509;
    margin-top: 10px;
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 10px 40px;
}
.face-book, .instaGram , .linkedIn{
    border-radius: 50px;
    
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    margin-top: 20px;
}
.line-between-top.container{
    height: 1px;
    width: 1200px;
    margin: 32px auto 0px auto;
    background: #F2F2F2;

}
.copy-right,.terms {
    color: #E5E5E5;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 30px;
    margin-bottom: 20px;
}