.how-itWorks.container {
    margin: 170px auto 30px auto;
    border-radius: 26px;
    background: #fff;
    max-width: 1200px;
    padding: 30px 0px 40px 0px;
}
h1.how-itWorks-header {
    padding-top: 10px;
    margin: 0px auto 40px auto;
    width: 600px;
}
.step-container {
    /* margin-right: 40px; */
    width: 800px;
    align-items: center;
    background:#2C84C5 ;
    border-radius: 10px;
    padding: 20px;
    text-align: left;
    color: #ffffff;
    margin-bottom: 20px;
}
.step-container-five{
    width: 800px;
    background:#2C84C5;
    border-radius: 10px;
    color: #ffffff;
    padding: 20px; 
    align-items: center;
    text-align: left; 
}
.number{
    font-size: 32px;
    font-family: "poppins";
    font-weight: 700;
    line-height: 40px;
    margin: 0px 21px;
}
.header-con-works{
    width: 1120px;
    margin: auto;
}
.header-underline-works{
    margin: 0px auto 30px auto;
  
}
/* .step{
    font-size: 16px;
    font-family: "poppins";
    font-weight: 400;
    margin-bottom: 0px;
    line-height: normal;
    text-align: left;
    padding: 0px 20px 0px 0px;

    text-align: left;
}
.line{
    width: 1px!important;
    background-color: #DAC509;
    height: 30px;
    margin: 0px 30px 0px 20px;
} */
.continue-btn{
    width: 200px;
    height: 50px;
    font-family: "poppins";
    font-weight: 700!important;
    font-size: 18px!important;
    line-height: 24px!important;
    background-color: #ffffff !important;
    margin: 40px auto 40px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #02416F;
    border: solid 2px #02416F;
    border-radius: 10px;
    
}
.continue-btn a{
    text-decoration: none!important;
    color: #02416F;
    font-weight: 700;
}
/* .steps-container{
    margin: auto;
    margin: auto;
    width: 800px;
} */