.searchBtn {
  height: 48px;
  background-color: #dac509 !important;
  border: 1px solid #000 !important;
  border-left: 0px !important;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  padding: 10px 40px;
  position: relative;
  right: 5px;
  text-align: center;
  top: 10%;
  align-items: center;
  background: #dac509;
  border-radius: 7px;
  color: #fff;
  display: flex;
}
.searchBox {
  display: flex;
  /* margin-top: 8%; */
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  border: 2px solid #02416F 2px!important;
}

.searchBox input {
  flex: 1;
  padding: 30px 20px;
  border:none !important;
}

.searchBox button {
  padding-right: 10px;
  box-shadow: 5px 4px 6px rgba(0, 0, 0, 0.19);
  border: none;
  cursor: pointer;
  cursor: hand;
  border:none !important;
}
