.checkout {
  min-height: 70vh;
  display: flex;
  margin-bottom: 5vh;
  /* margin-top: 20vh; */
}
.checkout-listings {
  width: 60%;
  min-height: 70vh;
  margin: 24px;
  margin-left: 12%;
  padding: 12px;
  border-radius: 10px;
  border: 1px;
  border-color: #f2f2f2;
}
.checkoutTitle {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
}
.order-summary {
  min-height: 70vh;
  margin: 24px;
  width: 30%;
  padding: 12px;
  border-radius: 8px;
  border: 1px;
  gap: 24px;
}
.input-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 4%;
  margin-bottom: 12px;
}
.input-field {
  width: 280px;
  padding: 12px;
  margin: 6px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  color: #02416f;
}
.input-title {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #02416f;
}
.date-picker {
  width: 280px;
  padding: 12px;
  margin: 6px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  color: #02416f;
}
.link{
  font-size: 12px;
  cursor: pointer;
  padding-left: 4px;
  text-decoration: underline;
}
.note-area{
  min-height: 150px;
  min-width: 200px;
  width: 60%;
  border-color: 1px solid #f2f2f2;
  border-radius: 5px;
  display: inherit;
}